/** @jsx jsx */
import React from "react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { Styled, jsx } from "theme-ui"
import "rc-slider/assets/index.css"
import Layout from "../../components/Layout"

export default function PageTemplate({ data: { mdx } }) {
  return (
    <Styled.root>
      <Layout>
        <div
          sx={{
            variant: "styles.shadowBox",
          }}
        >
          <Styled.h1>{mdx.frontmatter.title}</Styled.h1>
          <MDXRenderer>{mdx.body}</MDXRenderer>
        </div>
      </Layout>
    </Styled.root>
  )
}

export const pageQuery = graphql`
  query BlogPostQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
      }
    }
  }
`
